import { KeySetterAction, KeySetterActionType, KeySetterState } from './CustomNotification.type';

export const initialState: KeySetterState = {
    key: {}, // Initial state with an empty key
    productId: '',
};

/**
 * Reducer to handle the `SET_KEY` and `REMOVE_KEY` actions.
 * @param state - Current state of the KeySetter.
 * @param action - Action dispatched to update the state.
 * @returns {KeySetterState} - The updated state with the new key or reset key.
 * @namespace TrouperPwa/Store/CustomNotification/Reducer/CustomNotificationReducer
 */
export const CustomNotificationReducer = (
    state = initialState,
    action: KeySetterAction,
): KeySetterState => {
    switch (action.type) {
    case KeySetterActionType.SET_KEY:
        return {
            ...state,
            key: action.key, // Set the new key
            productId: action.productId,
        };

    case KeySetterActionType.REMOVE_KEY:
        return {
            ...state,
            key: {}, // Reset the key to an empty string
            productId: undefined,
        };

    default:
        return state;
    }
};

export default CustomNotificationReducer;
