/* eslint-disable @scandipwa/scandipwa-guidelines/export-level-one */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

// eslint-disable-next-line react/no-deprecated
import { createRoot } from 'react-dom/client'; // Use createRoot instead of render

import App from 'Component/App';
import { waitForPriorityLoad } from 'Util/Request/LowPriorityLoad';

import 'Style/main';

// let's register service-worker
waitForPriorityLoad().then(
    /* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
    /** @namespace Render/waitForPriorityLoad/then */
    () => {
        if ('serviceWorker' in navigator) {
            const swUrl = '/service-worker.js';
            navigator.serviceWorker.register(swUrl, { scope: '/' })
                .then((registration) => {
                    console.log('Service Worker registered: ', registration);
                })
                .catch((error) => {
                    console.error('Service Worker registration failed: ', error);
                });
        }

        if (window.metaHtml) {
            const doc = new DOMParser().parseFromString(window.metaHtml, 'text/html');
            Object.values(doc?.head?.children || {}).forEach((node) => {
                document.head.appendChild(node);
            });
        }
    },
);

// Code below enables the hot reloading of plugins
// Why? I have no idea. Further debugging needed.
// TODO: understand why this helps HMR
if (module.hot) {
    module.hot.accept();
}

// Modified HotApp component with error boundaries for better error handling
function HotApp() {
    return <App />;
}

window.onload = function () {
    const rootElement = document.getElementById('root');

    if (rootElement) {
        const root = createRoot(rootElement);
        console.log('Root element is found.');

        root.render(<HotApp />);
    } else {
        console.error('Root element not found. Make sure an element with id="root" exists in your HTML.');
    }
};
